import {
	Box,
	Divider,
	Flex,
	Link,
	LinkBox,
	LinkOverlay,
	Text,
	VStack,
	Icon,
	Button,
	Collapse,
	StackDivider,
	useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import {
	FiChevronDown,
	FiChevronUp,
	FiClock,
	FiFileText,
	FiGlobe,
	FiMap,
	FiMapPin,
	FiShoppingCart,
	FiUsers,
} from 'react-icons/fi';
import { NestoCart } from '../../components/svgs/NestoCart';
import { NestoLogo } from '../../components/svgs/NestoLogo';
import { StoresIcon } from '../../components/svgs/StoresIcon';
import { Modules } from '../../constants';
import { ModuleTypes } from '../../types';
import { getRefreshToken, getUser } from '../../utils/authFunctions';
import { setCurrentModule } from '../../utils/moduleFunctions';
import { SideNavDropdown } from '../components/SideNavDropdown';
import { SideNavLink } from '../components/SideNavLink';
import { useAppSelector } from '../redux/hooks';

interface SideBarProps {}

export const SideBar: React.FC<SideBarProps> = () => {
	const user = getUser();
	const { isSidenavOpen } = useAppSelector((state) => state.layout);
	const { isOpen, onToggle } = useDisclosure();
	const onModulePick = (s: ModuleTypes) => {
		setCurrentModule(s);
		window.location.reload();
	};
	const loadExternalModule = (link: String) => {
		const token = getRefreshToken();
		window.location.replace(`${link}?token=${token}`);
	};
	const hasPermission = (name: string) => {
		let perm = user.filter((p: any) => p.code === name)[0];
		if (!perm.is_read) {
			return false;
		}
		return true;
	};
	return (
		<>
			{/* <IconButton
				aria-label='toggle sidenav'
				icon={isSidenavOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
				onClick={
					isSidenavOpen
						? () => dispatch(closeSidenav())
						: () => dispatch(openSidenav())
				}
				position='sticky'
				top='90px'
				left={isSidenavOpen ? '220px' : '36px'}
				size='xs'
				rounded='full'
				variant='outline'
				zIndex='3'
				bg='white'
				transition='all 150ms linear'
			/> */}
			<Flex
				height='100vh'
				width={isSidenavOpen ? '235px' : '50px'}
				bg='white'
				boxShadow='2px 2px 16px rgba(0, 0, 0, 0.09)'
				zIndex={1}
				position='fixed'
				top='0'
				left='0'
				overflowX='hidden'
				transition='width 150ms ease-in-out'
				_hover={{ width: '235px' }}
				direction='column'
				justifyContent='space-between'
			>
				<Box>
					<LinkBox py='6' px={isSidenavOpen ? '6' : '1'} whiteSpace='nowrap'>
						<LinkOverlay as={Link} to='/' mr='2'>
							<NestoCart color='primary.500' />
						</LinkOverlay>
						<NestoLogo color='primary.500' />
					</LinkBox>
					<Divider />
					<Box mt='4'>
						<VStack alignItems='start' spacing={4}>
							{/* <SideNavLink linkTo='/users'>
								<Flex alignItems='center'>
									<Icon as={FiUsers} h='5' w='5' />
									<Text ml='4'>Users</Text>
								</Flex>
							</SideNavLink> */}
							<SideNavLink linkTo='/sites'>
								<Flex alignItems='center'>
									<Icon as={StoresIcon} h='5' w='5' />
									<Text ml='4'>Sites</Text>
								</Flex>
							</SideNavLink>
							<SideNavLink linkTo='/timeslots'>
								<Flex alignItems='center'>
									<Icon as={FiClock} h='5' w='5' />
									<Text ml='4'>Timeslots</Text>
								</Flex>
							</SideNavLink>
							<SideNavDropdown linkTo='zms' label='Zone Management'>
								<VStack>
									<SideNavLink linkTo='/zms/cities'>
										<Flex alignItems='center'>
											<Icon as={FiGlobe} h='5' w='5' />
											<Text ml='4'>City</Text>
										</Flex>
									</SideNavLink>
									<SideNavLink linkTo='/zms/zones'>
										<Flex alignItems='center'>
											<Icon as={FiMap} h='5' w='5' />
											<Text ml='4'>Zone</Text>
										</Flex>
									</SideNavLink>
									<SideNavLink linkTo='/zms/areas'>
										<Flex alignItems='center'>
											<Icon as={FiMapPin} h='5' w='5' />
											<Text ml='4'>Area</Text>
										</Flex>
									</SideNavLink>
								</VStack>
							</SideNavDropdown>
							<SideNavDropdown linkTo='oms' label='Order Management'>
								<VStack>
									<SideNavLink linkTo='/oms/orders'>
										<Flex alignItems='center'>
											<Icon as={FiShoppingCart} h='5' w='5' />
											<Text ml='4'>Orders</Text>
										</Flex>
									</SideNavLink>
									<SideNavLink linkTo='/oms/reports'>
										<Flex alignItems='center'>
											<Icon as={FiFileText} h='5' w='5' />
											<Text ml='4'>Reports</Text>
										</Flex>
									</SideNavLink>
								</VStack>
							</SideNavDropdown>
						</VStack>
					</Box>
				</Box>
				<Divider />
				<Box>
					{Modules.filter((m) => m.key === 'zms').map((module) => {
						return (
							<Button
								onClick={onToggle}
								isFullWidth
								colorScheme='primary'
								variant='ghost'
								leftIcon={<module.icon />}
								rightIcon={isOpen ? <FiChevronDown /> : <FiChevronUp />}
								key={module.key}
								rounded='none'
								py='6'
								justifyContent='start'
							>
								{module.name}
							</Button>
						);
					})}
					<Divider />

					<Collapse in={isOpen} animateOpacity>
						<VStack divider={<StackDivider />}>
							{Modules.filter((m) => m.key !== 'zms').map((module) => {
								if (!hasPermission(module.accessName)) {
									return null;
								}
								return (
									<Button
										key={module.key}
										isFullWidth
										rounded='none'
										bg='white'
										color={`${module.color}.600`}
										leftIcon={<module.icon />}
										_hover={{ bg: 'primary.100' }}
										onClick={() =>
											module.isLinked
												? loadExternalModule(module.loginUrl!)
												: onModulePick(module.key)
										}
										py='6'
										justifyContent='start'
									>
										{module.name}
									</Button>
								);
							})}
						</VStack>
					</Collapse>
				</Box>
			</Flex>
		</>
	);
};
